import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import BaseLayout from "../../components/layout"
import Seo from "../../components/seo";
import HorizontalRule from "../../components/horizontal-rule";
import BreadcrumbTrail from "../../components/breadcrumb-trail";

export default function PrivacyPolicyPage(props) {
  const { site } = useStaticQuery(query)

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Privary Policy',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="Privacy Policy" pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
      <div className="layout-base-w app-p-base">
        <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
        <div className="mb-16 md:mb-28">
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 className="text-header-1 w-full">Privacy Policy</h1>
          </div>
        </div>

        <div className="layout-reading-w app-px-base article-layout article-layout--standardFont">
          <h4>Disclaimer</h4>
          <p>{ site.siteMetadata.company } collects important customer information in an effort to enhance and personalize your { site.siteMetadata.company }
            experience. This information allows us to communicate with our customers about our products, services and
            promotions. The privacy policy (the “Privacy Policy”) described below covers the entire { site.siteMetadata.company } website (“the Site,” “we,” “us,” or “our”). Phrases such as “you,” “your” and other similar expressions
            refer to our customers, or the specific users of this Site. By visiting and using the Site, you accept the
            practices described in this Privacy Policy.</p>

          <h4 className="mt-6">Collected Information</h4>
          <p>When you register on our Site, submit your account information, or participate in { site.siteMetadata.company } promotions
            or surveys, our Site collects your personal information such as your name, email address, mailing
            address and payment information (credit card numbers and banking accounts). { site.siteMetadata.company } will use your
            information collected online to process and fulfill your order. Additionally, your email address may be
            collected at various points within the Site (including when you hire us) so that we can send you any necessary
            email messages related to your { site.siteMetadata.company } experience. In addition, we maintain a record of your Site
            usage and any other account profile details that might enable us to improve your { site.siteMetadata.company } experience.
            Analytics such as Site use and traffic patterns are also considered to help us improve the design of our
            website, the products we make and exclusive services we offer. At { site.siteMetadata.company }, we understand and
            recognize that we must maintain and use customer information responsibly. Please note: You are not required to
            submit any information to our Site. However, without providing the requested information, you may be unable to
            access certain features of our Site.</p>

          <h4 className="mt-6">Additional Means of Obtaining Information</h4>
          <h2 className="mt-4 mb-1">Cookies</h2>
          <p>As you navigate our site, your preferences are remembered through the temporary use of “session” cookies.
            Cookies enable our systems to gather information about your browser as well as monitor the navigational
            patterns you take while browsing the site. Site users have the option to accept or disable cookies at any time
            through their browsers. If you choose to disable your cookies, your user experience may be limited.</p>
          <h2 className="mt-6 mb-1">Site Statistics</h2>
          <p>Web data is collected to monitor user trends on our Site. Analytical data such as hits to our server, traffic
            patterns and page views shows us where our audience is coming from and how they interact with us online. This
            type of collected information does not personally identify specific Site users. In addition, like most
            websites, our Site may utilize “web beacons,” “pixel tags” or other tracking technologies to help us study the
            actions of our users through non-personally identifiable information. This data may be aggregated with similar
            data collected from other users to help us improve { site.siteMetadata.company } products, services and our overall Site
            experience.</p>

          <h4 className="mt-6">Information Sharing with Outside Parties</h4>
          <p>{ site.siteMetadata.company } will never share, rent or sell your email address for any reason. We do not share your
            personal information with third-party companies, though we may provide aggregate Site statistics and related
            information to reputable third party vendors. This data will never include personal information. We do not
            share credit card information with third parties except for the purpose of processing your order payment and
            as required by law. If for any reason you choose to access a third party website linked to our Site, you do so
            at your own risk. We are not held responsible for how these outside parties collect, use, protect or disclose
            the information you provide them.</p>

          <h4 className="mt-6">Age Requirement</h4>
          <p>{ site.siteMetadata.siteUrl } is intended for users who are 18 years of age and older. If you are under the age of 18, you are
            not permitted to submit any personal information to us.</p>

          <h4 className="mt-6">Security</h4>
          <p>We employ top physical, electronic, and administrative safety measures to assist us in protecting your
            personal information and credit card data. These safeguards help us to prevent fraud and unauthorized access
            as well as maintain data accuracy. In addition, we use professionally reasonable efforts to block access to
            your personal information from { site.siteMetadata.company } employees and corporate partners to ensure your personal
            information is always kept safe.</p>

          <h4 className="mt-6">Modification of Policies</h4>
          <p>It is at our sole discretion that { site.siteMetadata.company } may modify this Privacy Policy to better serve our
            customers. Please check back often to review any new modifications that have been made.</p>

          <h4 className="mt-6">Summary</h4>
          <p>{ site.siteMetadata.company } takes your privacy seriously. When connecting with us, we want our customers to feel
            confident that their personal information and credit card data is protected. Just as we are dedicated to
            bringing you the very best content, we are equally committed to providing our customers with a safe and secure
            online experience. The personal information we collect from our Site helps us better understand the interests
            of our audience in an ongoing effort to bring you improved service. If you have questions or suggestions
            regarding our privacy standards please email { site.siteMetadata.emailAddress }.</p>
        </div>
      </div>
    </BaseLayout>
  )
}

const query = graphql`
  query PrivacyPolicy {
    site {
      siteMetadata {
        company
        siteUrl
        emailAddress
      }
    }
  }
`
